import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Initiatives() {
  const [initiatives, setInitiatives] = useState();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    axios
      .get("https://backend.ecell.org.in/getdetails/getall?auth=website")
      .then((res) => {
        setInitiatives(res.data.initiatives);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="initiatives-container">
      <h2 className="initiative-header">Our initiatives</h2>
      {initiatives && (
        <div className="initiatives">
          <Carousel responsive={responsive}>
            {initiatives.map((i, idx) => (
              <div className="initiative" key={idx}>
                <img src={i.image} alt="kiit ecell" />
                <span>{i.name}</span>
                <span className="initiative-desc">{i.description}</span>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
}

export default Initiatives;
