import React from "react";
import ecellLogo from "./Ecell logo.png";
import KiitLogo from "./kiit.png";
import { Link } from "react-router-dom";

const FooterDurgesh = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-8 pb-24 tablet:flex-row">
        <div className="flex flex-col basis-[33%] gap-4">
          <div className="flex items-center justify-center gap-2">
            <img className="h-16 w-26" src={KiitLogo} alt="KiitLogo" />
            <img className="w-28 h-30" src={ecellLogo} alt="KiitLogo" />
          </div>
          <div className="flex flex-col text-gray-400">
            <p className="text-gray-300">7B,Second Floor,</p>
            <p className="text-gray-300">TBI Biotech Campus,</p>
            <p className="text-gray-300">KIIT University,Bhubaneswar</p>
          </div>
          <p className="text-gray-300">+91-9330989537</p>
          <p className="text-gray-300">Mail : pcr@ecell.org.in</p>
        </div>

        <div className="flex flex-col items-center justify-center w-full gap-12 mt-5 tablet:items-start tablet:flex-row tablet:gap-24">
          <div className="flex flex-col gap-5">
            <p className="text-xl text-white">Usefull Links</p>
            <div className="flex flex-col items-start gap-3">
              <p className="text-gray-400 text-md">
                <Link to="/">Home</Link>
              </p>
              <p className="text-gray-400 text-md">
                <Link to="/startups">Startups</Link>
              </p>
              <p className="text-gray-400 text-md">
                <Link to="/blogs">Blogs</Link>
              </p>
              <p className="text-gray-400 text-md">
                <Link to="/aboutus">About us</Link>
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <p className="text-xl text-white">Social Media</p>
            <div className="flex flex-col items-start gap-3">
              <a
                className="text-gray-400 text-md"
                href="https://www.linkedin.com/company/kiitecell/mycompany/"
              >
                LinkedIn
              </a>
              <a
                className="text-gray-400 text-md"
                href="https://www.facebook.com/kiitecell"
              >
                Facebook
              </a>
              <a
                className="text-gray-400 text-md"
                href="https://www.instagram.com/ecell_kiit/"
              >
                Instagram
              </a>
              <a
                className="text-gray-400 text-md"
                href="https://twitter.com/kiit_ecell"
              >
                Twitter
              </a>
            </div>
          </div>
        </div>
      </div>

      <p className="text-center">
        Made With <i style={{ color: "red" }} class="fas fa-heart"></i> by KIIT
        ECell Copyrights Reserved.
      </p>
    </div>
  );
};

export default FooterDurgesh;
