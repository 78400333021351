import React, { useState } from "react";
import Typewriter from "typewriter-effect";
import HeroSvgMain from "./assets/HeroSvgMain.svg";
import axios from "axios";
import { AiOutlineMail } from "react-icons/ai";

const HeroSection = () => {
  const [input, setInput] = useState("");
  const emailset = (e) => {
    setInput(e.target.value);
    
  }
  const request = () => {
    axios.post('https://ecell.shubhtoy.repl.co/email',{
      email: input,
    }
    ).then(response => {
      console.log(response)
    }).catch(response => {
      console.log(response)
    })
  }
  return (
    <div className="flex flex-col items-center w-full h-screen gap-6 pt-12 mb-52">
      {/* <img
        className="absolute -left-0 -top-56 scale-y-[0.6] z-0"
        src={mainVectorLeft}
        alt="bg Vector left"
      />
      <img
        className="absolute right-0 z-0 -top-56"
        src={mainVectorRight}
        alt="bg Vector left"
      /> */}
      <p className="z-10 text-5xl font-bold text-white tablet:text-9xl">
        KIIT E-Cell
      </p>
      <img
        className="absolute top-0 z-0 object-none w-[1440px] h-[1600px] overflow-hidden"
        src={HeroSvgMain}
        alt="bg Vector left"
      />
      <p className="z-10 flex flex-col gap-2 text-xl text-gray-300 sm:flex-row">
        <p className="pl-6 text-3xl text-gray-300"> Where Your Ideas </p>
        <span className="pr-4 text-3xl text-gray-300">
          <Typewriter
            options={{
              strings: [
                "  Are Imagined",
                "  Are Innovated",
                "  Get Implemented",
              ],
              autoStart: true,
              loop: true,
              pauseFor: 2000,
            }}
          />
        </span>
        {/* Heard, Discussed, And Put Into Action */}
      </p>
      <div className="flex flex-col items-center w-full tablet:flex-row max-w-[560px] gap-4 mobileL:px-0 px-4">
          <div className="relative flex justify-center w-full ">
            <AiOutlineMail className="absolute w-5 h-5 -translate-y-1/2 pointer-events-none left-3 top-1/2" />
            <input
              className="py-2 pl-12 rounded-sm grow bg-slate-700 placeholder:text-zinc-300 placeholder:text-sm"
              type="text"
              placeholder="Email"
              value={input}
              onChange={emailset}
            />{" "}
          </div>
          <button className="px-2 py-2 text-xs text-gray-100 z-0 font-bold bg-gradient-to-r from-[#25A8E0] to-[#4936D8] rounded-sm whitespace-nowrap" onClick={request}>
          Subscribe to our Newsletter
        </button>
        </div>
    </div>
  );
};

export default HeroSection;
