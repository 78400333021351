import React, { useEffect } from "react";
import "./about.css";
import Notify from "./Notify";

import Departments from "./Departments";
import Initiatives from "./Initiatives";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about">
      <div className="intro">
        <p className="mt-16 text-5xl font-bold text-blue-500 tablet:text-8xl">
          ABOUT US
        </p>
        <div>
          <p className="px-4 mt-8 text-lg text-white tablet:px-24 tablet:text-2xl">
            Where your ideas are heard, discussed, and put into action.
          </p>
        </div>
        <div className="p-4">
          <div className="box b-1">
            <img
              src="https://i.imgur.com/zQxZ9Y5h.jpg"
              alt="omega"
              className="group-img"
            />
          </div>
          <div className="flex flex-col items-center justify-center py-8 gap-14 tablet:gap-0 tablet:px-8 tablet:flex-row">
            <p className="tablet:max-w-[50%] text-justify text-white tablet:pr-8">
              KIIT E-CELL is dedicated to nurturing entrepreneurship culture
              among young and enthusiastic minds and helping them develop the
              perseverance muscle to walk the extra mile. We are keen to uphold
              budding entrepreneurs who seek to tackle the challenges of people
              through groundbreaking technological solutions; by implementing
              the assistance required with East India's largest inbuilt
              technology business incubator, KIIT TBI. We ensure holistic
              development and a conducive learning environment for our students
              by hosting start-up talks, innovation challenges, workshops,
              keynote sessions by celebrated entrepreneurs, internship camps,
              and much more. The Cell is drawn to enhancing the hustle of young
              minds who are determined and driven.
            </p>
            <p className="tablet:max-w-[50%] text-justify text-white tablet:pl-8">
              All our societal activities such as Discussion forums, Speaker
              sessions, B-plan competitions, summer internships, webinars, and
              more, by eminent international personalities, provide the tools
              you need to turn your dreams into reality. We deeply encourage the
              self-development of our members through various initiatives and
              programs, and help them grow into individuals that help shape the
              corporate world, and are always on the lookout for young minds
              that can help us move towards this goal, together!
            </p>
          </div>
        </div>
      </div>

      <Departments />
      <Initiatives />
      <Notify />
    </div>
  );
};

export default AboutUs;
