import React, { useState, useEffect } from "react";
import axios from "axios";

import "./assets/companiesSwiperStyles.css";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    setLoading(true);
    console.log("loading....");
    axios
      .get("https://ecell-backend-ophn.onrender.com/getdetails/getall?auth=website")
      .then((res) => {
        setCompanies(res.data.collaborators);
        setLoading(false);
        console.log(loading);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        console.log(loading);
      });
  }, []);

  return (
    <div className="z-10 flex flex-col items-center w-full gap-8 mb-40 pt-[600px]">
      <p className="z-10 mb-4 text-4xl font-bold text-white">
        Trusted By Companies Like These
      </p>
      {companies && (
        <div className="initiatives">
          <Carousel responsive={responsive}>
            {companies.map((i, idx) => (
              <img key={idx} className="p-4 " src={i.logo} alt={i.name} />
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default Companies;
