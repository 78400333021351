import React from "react";
import vector1 from "./assets/Vector.svg";
import departmentsPic from "./assets/departmentsPic.png";
import designVec from "./assets/DesignWork1.svg";
import techVec from "./assets/Technology.svg";
import researchVec from "./assets/Research.svg";
import contentVec from "./assets/Content.svg";
import pcrVec from "./assets/PublicRelations.svg";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";

const Departments = () => {
  return (
    <div className="flex flex-col items-center w-full gap-24 pt-24 pb-14">
      {/* COLLABORTIONS AND STARTUPS WALA PART */}

      <div className="flex flex-col items-center max-w-6xl px-14 sm:px-48 py-10 text-white bg-gradient-to-r from-[#25A8E0] to-[#4936D8] rounded-2xl">
        <p className="text-4xl font-bold text-center text-white">
          IMAGINE, INNOVATE & IMPLEMENT
        </p>
        <p className="mt-2 text-sm text-center text-white">
          KIIT E-CELL is dedicated to nurturing entrepreneurship culture among
          young and enthusiastic minds and helping them develop the perseverance
          muscle to walk the extra mile.
        </p>
        <div className="flex flex-wrap justify-center gap-2 mt-10 text-white">
          <div className="flex flex-col gap-2 max-w-[250px] items-center">
            <p className="flex text-6xl font-bold text-white">
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                {({ isVisible }) => (
                  <div style={{ height: 45 }}>
                    {isVisible ? (
                      <CountUp end={16} duration={0.7} preserveValue={true} />
                    ) : (
                      16
                    )}
                  </div>
                )}
              </VisibilitySensor>
              +
            </p>
            <p className="text-xl text-center text-white">
              Startups we've helped curate themselve.
            </p>
          </div>

          <div className="flex flex-col gap-2 max-w-[250px] items-center">
            <p className="flex text-6xl font-bold text-white">
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                {({ isVisible }) => (
                  <div style={{ height: 45 }}>
                    {isVisible ? (
                      <CountUp end={30} duration={0.7} preserveValue={true} />
                    ) : (
                      30
                    )}
                  </div>
                )}
              </VisibilitySensor>
              +
            </p>
            <p className="text-xl text-center text-white">
              Big brands we've collaborated with.
            </p>
          </div>
        </div>
      </div>

      {/* OUR DEPARTMENTS WALA PART */}

      <div className="flex flex-col items-center gap-20 mt-32 max-w-7xl">
        <p className="text-4xl font-bold text-white sm:text-6xl">
          Our Departments
        </p>
        <div className="flex flex-col items-center justify-center gap-20 tablet:flex-wrap tablet:flex-row ">
          <div className="relative tablet:max-w-[30%] max-w-[50%] ">
            <img
              className="absolute"
              src={departmentsPic}
              alt="Departments Pic"
            />
            <img className="" src={vector1} alt="trial" />
          </div>

          <div className="w-[100%] tablet:p-0 px-6 tablet:max-w-[50%]">
            <div className="flex flex-col justify-start gap-8 tablet:items-center tablet:flex-row tablet:flex-wrap">
              <div className="flex gap-4 justify-center items-center text-center rounded-tl-3xl rounded-br-3xl py-2 px-10 text-lg font-bold tracking-wide min-w-[280px] bg-[#15141E] text-white">
                <img className="scale-75" src={designVec} alt="vec" />
                <p className="text-3xl font-bold tracking-wide text-center text-white">
                  Design
                </p>
              </div>
              <div className="flex gap-4 justify-center items-center text-center rounded-tl-3xl rounded-br-3xl py-3 px-10 text-lg font-bold tracking-wide min-w-[280px] bg-[#15141E] text-white">
                <img className="scale-75" src={techVec} alt="vec" />
                <p className="text-3xl font-bold tracking-wide text-center text-white">
                  Tech
                </p>
              </div>
              <div className="flex gap-4 justify-center items-center text-center rounded-tl-3xl rounded-br-3xl py-2 px-10 text-lg font-bold tracking-wide min-w-[280px] bg-[#15141E] text-white">
                <img className="scale-75" src={researchVec} alt="vec" />
                <p className="text-3xl font-bold tracking-wide text-center text-white">
                  R&D
                </p>
              </div>
              <div className="flex gap-4 justify-center items-center text-center rounded-tl-3xl rounded-br-3xl py-2 px-10 text-lg font-bold tracking-wide min-w-[280px] bg-[#15141E] text-white">
                <img className="scale-75" src={contentVec} alt="vec" />
                <p className="text-3xl font-bold tracking-wide text-center text-white">
                  Content
                </p>
              </div>
              <div className="flex gap-4 justify-center items-center text-center rounded-tl-3xl rounded-br-3xl py-2 px-10 text-lg font-bold tracking-wide min-w-[280px] bg-[#15141E] text-white">
                <img className="scale-75" src={pcrVec} alt="vec" />
                <p className="text-3xl font-bold tracking-wide text-center text-white">
                  PCR
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departments;
