import React, { useState, useEffect } from "react";
import "./about.css";
import axios from "axios";
import AarushiImg from "./directorImages/AarushiDi.jpg";
import NishthaImg from "./directorImages/NishthaDi.jpeg";
import ShambahviImg from "./directorImages/SHAMBHAVI.jpg";
import SmritiDiImg from "./directorImages/SmritiDi.jpg";
import BarneetImg from "./directorImages/BARNEET.jpg";

function Departments() {
  const [management, setManagement] = useState();
  useEffect(() => {
    axios
      .get("https://backend.ecell.org.in/getdetails/getall")
      .then((res) => {
        setManagement(res.data.management);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="flex items-center justify-center w-full">
      <div className="flex mb-40 flex-col gap-8 max-w-[1174px] items-center justify-center px-2">
        <h2 className="initiative-header" style={{ marginBottom: "100px" }}>
          Our departments
        </h2>
        {management && (
          <>
            <div className="relative pt-24 bg-gradient-to-b from-[#15141e] to-[#15141e]/40 tablet:px-8 laptop:pt-6 rounded-tl-3xl rounded-br-3xl">
              <p className="absolute top-0 left-0 w-full py-4 text-center text-white bg-gradient-to-r from-[#25A8E0] to-[#4936D8] mobileL:w-96 rounded-tl-3xl rounded-br-3xl">
                Research and Department
              </p>
              <div className="flex flex-col items-center justify-between w-full gap-6 px-2 py-4 tablet:gap-0 tablet:flex-row tablet:px-14">
                <p className="tablet:w-[80%] text-justify tablet:text-left text-white">
                  The RnD Department promotes and develops the knack of business
                  research amongst the students by putting together a support
                  plan for the organization. The field of research includes
                  business, startups, and bringing various opportunities for
                  students of KIIT from the corporate world. Any event organized
                  by KIIT E-Cell which you liked? Well, thanks to the R&D
                  department for making it possible!
                </p>

                <div className="flex flex-col items-center gap-3">
                  <img
                    src={SmritiDiImg}
                    alt=""
                    className="w-40 h-40 rounded-full"
                  />
                  <div>
                    <p className="text-white">{management[4]?.name}</p>
                    <p className="text-white/70">
                      {management[4]?.designation}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative pt-24 bg-gradient-to-b from-[#15141e] to-[#15141e]/40 tablet:px-8 laptop:pt-6 rounded-tl-3xl rounded-br-3xl">
              <p className="absolute top-0 left-0 w-full py-4 text-center text-white bg-gradient-to-r from-[#25A8E0] to-[#4936D8] mobileL:w-96 rounded-tl-3xl rounded-br-3xl">
                Design
              </p>
              <div className="flex flex-col items-center justify-between w-full gap-6 px-2 py-4 tablet:gap-0 tablet:flex-row tablet:px-14">
                <p className="tablet:w-[80%] text-justify tablet:text-left text-white">
                  This department is responsible for the look and feel of KIIT
                  E-Cell. From setting appropriate colors to deciding the most
                  suitable font, everything is taken care of by this department.
                  They say, “The first impression is the last impression.” The
                  designers are responsible for making your first impression of
                  the Cell last longer than ever.
                </p>

                <div className="flex flex-col items-center gap-3">
                  <img
                    src={ShambahviImg}
                    alt=""
                    className="w-40 h-40 rounded-full"
                  />
                  <div>
                    <p className="text-white">{management[2]?.name}</p>
                    <p className="text-white/70">
                      {management[2]?.designation}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative pt-24 bg-gradient-to-b from-[#15141e] to-[#15141e]/40 tablet:px-8 laptop:pt-6 rounded-tl-3xl rounded-br-3xl">
              <p className="absolute top-0 left-0 w-full py-4 text-center text-white bg-gradient-to-r from-[#25A8E0] to-[#4936D8] mobileL:w-96 rounded-tl-3xl rounded-br-3xl">
                Content
              </p>
              <div className="flex flex-col items-center justify-between w-full gap-6 px-2 py-4 tablet:gap-0 tablet:flex-row tablet:px-14">
                <p className="tablet:w-[80%] text-justify tablet:text-left text-white">
                  The content team comprises some talented and accomplished
                  minds who are responsible for brainstorming ideas and
                  releasing quality content for KIIT E-Cell. The team writes and
                  edits content for different formats like MoUs, emails, poster
                  copy, captions, and website content. In fact, everything that
                  you are reading right now has been curated by the content
                  writers of KIIT E-Cell!
                </p>

                <div className="flex flex-col items-center gap-3">
                  <img
                    src={NishthaImg}
                    alt=""
                    className="w-40 h-40 rounded-full"
                  />
                  <div>
                    <p className="text-white">{management[7]?.name}</p>
                    <p className="text-white/70">
                      {management[7]?.designation}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative pt-24 bg-gradient-to-b from-[#15141e] to-[#15141e]/40 tablet:px-8 laptop:pt-6 rounded-tl-3xl rounded-br-3xl">
              <p className="absolute top-0 left-0 w-full py-4 text-center text-white bg-gradient-to-r from-[#25A8E0] to-[#4936D8] mobileL:w-96 rounded-tl-3xl rounded-br-3xl">
                Tech
              </p>
              <div className="flex flex-col items-center justify-between w-full gap-6 px-2 py-4 tablet:gap-0 tablet:flex-row tablet:px-14">
                <p className="tablet:w-[80%] text-justify tablet:text-left text-white">
                  The technical department meets any critical gaps in the
                  technological needs. This team comprises technophiles who look
                  after the “technicalities” of KIIT E-cell. These techies are
                  responsible for converting coffee into code. From websites to
                  mobile apps- you name it, they got it. This team is the reason
                  why the cell is online and running.
                </p>

                <div className="flex flex-col items-center gap-3">
                  <img
                    src={AarushiImg}
                    alt="Tech Director Img"
                    className="w-40 h-40 rounded-full"
                  />
                  <div>
                    <p className="text-white">{management[0]?.name}</p>
                    <p className="text-white/70">
                      {management[0]?.designation}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative pt-24 bg-gradient-to-b from-[#15141e] to-[#15141e]/40 tablet:px-8 laptop:pt-6 rounded-tl-3xl rounded-br-3xl">
              <p className="absolute top-0 left-0 w-full py-4 text-center text-white bg-gradient-to-r from-[#25A8E0] to-[#4936D8] mobileL:w-96 rounded-tl-3xl rounded-br-3xl">
                PCR
              </p>
              <div className="flex flex-col items-center justify-between w-full gap-6 px-2 py-4 tablet:gap-0 tablet:flex-row tablet:px-14">
                <p className="tablet:w-[80%] text-justify tablet:text-left text-white">
                  Public and Corporate Relations is the face of KIIT E-Cell.
                  Needless to mention, this department is the point of contact
                  of the Cell to the corporate world. PCR involves scouting for
                  potential partnerships with individuals, organizations, and
                  companies for various affairs. The brilliant communicators of
                  the PCR team play a major role in organizing all the major
                  events of the cell.
                </p>

                <div className="flex flex-col items-center gap-3">
                  <img
                    src={BarneetImg}
                    alt=""
                    className="w-40 h-40 rounded-full"
                  />
                  <div>
                    <p className="text-white">{management[5]?.name}</p>
                    <p className="text-white/70">
                      {management[5]?.designation}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Departments;
