import React from "react";
import "../../assets/css/Mobile.css";
import mobileUi from "./assets/Mobileui.svg";
export default function Mobile() {
  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-6xl mobile">
        <p className="text-4xl font-bold text-white sm:text-6xl">
          Download The App Now!
        </p>
        <br />
        <br />
        <div className="px-10 mobile_desc">
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p> */}
        </div>
        <br />
        <br />
        <br />
        {/* <div className='bg'>
                <div className="mobile_sphere">
                    <img src = {phone} height = "525px" width = "370px" alt = "ecell_phone"/>
                </div>
            </div> */}
        <div className="back-grd">
          <img src={mobileUi} className="w-[600px] " alt="ecell" />
        </div>
        <br />
        <br />
        <br />
        <a
          href="https://play.google.com/store/apps/details?id=in.org.ecell.icamp"
          target="_blank"
          rel="noopener noreferrer"
          className="px-5 py-3 text-l text-white hover:from-[#25A8E0]/80 hover:to-[#4936D8]/80 font-bold bg-gradient-to-r from-[#25A8E0] to-[#4936D8] rounded-sm"
        >
          Download Now
        </a>
        <br />
        <br />
      </div>
    </div>
  );
}
