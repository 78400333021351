import React, { useEffect, useState } from "react";
import "./Event.css";
import "./eventAsset/css/animate.css";
//import "./eventAsset/css/bootstap.min.css";
import "./eventAsset/css/Card.css";
import "./eventAsset/css/default.css";
import "./eventAsset/css/flaticon.css";
import "./eventAsset/css/LineIcons.css";
import "./eventAsset/css/magnific-popup.css";
import "./eventAsset/css/slick.css";
import "./eventAsset/css/style.css";
import "./eventAsset/css/style.css.map";
// import { Balcony } from '@mui/icons-material';
import axios from "axios";
import { AiFillCalendar } from "react-icons/ai";
import { FaMoneyBill } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import event from "./Design XP webf.png";

const Events = () => {
  const [pastEvents, setPastEvents] = useState([]);
  useEffect(() => {
    const getPastEvents = async () => {
      try {
        const res = await axios.get(
          "https://ecell-backend-ophn.onrender.com/getdetails/event",
        );
        console.log(res);
        setPastEvents(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    getPastEvents();
  }, []);

  return (
    <div>
      <div id="" className="h-screen d-flex align-items-center bg-[#040D29]">
        <div className="container">
          <div className="flex flex-col items-start">
            <div className="flex gap-20">
              <div className="flex flex-col gap-4">
                <h3
                  className="text-[#25A8E0] text-2xl text-left"
                  style={{ color: "#25A8E0", fontSize: 30 }}
                >
                  Design XP 2022
                </h3>
                <h2 className="max-w-lg text-2xl text-left text-white mobileL:text-3xl">
                  Dive into the world of Design with us, this 14th and 15th, for
                  Design XP. Your idea. Our experience. Perfect Design.
                </h2>

                <div className="flex flex-col items-center justify-center gap-8 p-4 mt-8 rounded-lg mobileL:gap-16 mobileL:flex-row bg-white/60">
                  <div className="flex flex-col items-center gap-8 mobileL:items-baseline">
                    <div className="flex items-center gap-2">
                      <HiLocationMarker className="text-4xl text-blue-700" />
                      <p className="text-lg font-bold text-blue-700">
                        KIIT, Bhubaneswar
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <AiFillCalendar className="text-4xl text-blue-700" />
                      <p className="text-lg font-bold text-blue-700">
                        14th January 2022
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-8 mobileL:items-baseline">
                    <div className="flex items-center gap-2">
                      <FaMoneyBill className="text-4xl text-blue-700" />
                      <p className="text-lg font-bold text-blue-700">Free</p>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() =>
                          window
                            .open(
                              "https://docs.google.com/forms/d/e/1FAIpQLSfvtSNj9cnS5DEBZru2iSj1Hatlbs9YXseJ9wlseV2L9bN63A/viewform",
                              "_blank"
                            )
                            .focus()
                        }
                        className="px-4 py-2 font-semibold text-white transition-all duration-500 bg-blue-700 rounded-md hover:bg-blue-800"
                      >
                        Register Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden laptop:block">
                <img src={event} alt="ecell" className="max-w-sm" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        id="features"
        className="features-area pt-115 pb-130 gray-bg"
        style={{ backgroundColor: "black" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="pb-20 text-center section-title">
                <h2 className="title">Past Events</h2>
                <p className="text">
                  Some past events conducted by KIIT E-Cell
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center laptop:items-start laptop:flex-row">
            {pastEvents.map((event, i) => {
              return (
                <div className="h-full col-lg-4 col-md-6 col-sm-8">
                  <div
                    className="text-center single-features mt-30 wow fadeIn"
                    data-wow-duration="1s"
                    data-wow-delay="0s"
                  >
                    <div className="features-icon">
                      <i className="lni-microphone"></i>
                      <span>{i + 1}</span>
                    </div>
                    <div className="features-content">
                      <h4 className="features-title">
                        <a href="#">{event.name}</a>
                      </h4>
                      <p className="text">{event.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Events;
