import React, { useState, useEffect } from "react";
import "./BlogMobile.css";
import "./Blog.css";
import axios from "axios";
<meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>;
function Blog() {
  const [blogdata, setBlogData] = useState([]);
  useEffect(() => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@kiit-ecell"
      )
      .then((response) => {
        console.log(response.data);
        setBlogData(response.data.items);
      });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col items-center justify-start w-full h-full min-h-screen min-w-screen">
      <div className="flex flex-col items-center justify-center max-w-[1200px] gap-20 ">
        <h1 className="mt-16 text-5xl font-bold text-blue-500 tablet:text-8xl">
          BLOGS
        </h1>
        <div className="flex flex-wrap items-start justify-center w-full gap-10 mb-24">
          {/* <input className="search-box" type="text" placeholder="Search..."/> */}
          {blogdata.map((blog, id) => {
            return (
              <div
                key={id}
                className="flex flex-col text-white group gap-4 items-center justify-between w-full max-w-[300px] h-[574px] border-[2px] border-white/30 rounded-md"
              >
                <div className="w-full">
                  <img
                    src={blog.thumbnail}
                    alt=""
                    className="w-full h-[220px] rounded-t-md"
                  />
                  <div className="flex flex-col items-start w-full gap-3 p-2">
                    <p className="text-lg font-bold text-left text-white">
                      {blog.title}
                    </p>
                    <p className="text-xs font-medium">
                      - {blog.pubDate.split(" ")[0]} (
                      {`${blog.pubDate.split(" ")[1].split(":")[0]}:${
                        blog.pubDate.split(" ")[1].split(":")[1]
                      } hr`}
                      )
                    </p>

                    <p className="text-sm text-left">
                      {`${blog.content
                        .replace(/<[^>]+>/g, "")
                        .slice(0, 150)} ...`}
                    </p>
                    <div className="flex flex-wrap items-center justify-start gap-2 text-xs">
                      {blog.categories.slice(0, 4).map((cat, i) => {
                        return (
                          <p
                            key={i}
                            className="text-xs font-semibold text-blue-500/80"
                          >
                            #{cat}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => window.open(blog.link, "_blank")}
                  className="flex items-center justify-center w-full py-2 font-semibold text-center transition-all duration-300 bg-blue-500 group-hover:bg-white group-hover:text-blue-500 rounded-b-md"
                >
                  Read More
                </button>
              </div>

              // <div key={id} className="blog-card">
              //   <div className="left">
              //     <img src={blog.thumbnail} className="w-32 h-44" alt="" />
              //     <div className="blog-text">
              //       <span className="blog-heading">{blog.title}</span>
              //       <span className="blog-date">{blog.pubDate}</span>
              //       <p className="max-w-lg blog-content">
              //         {blog.content.replace(/<[^>]+>/g, "").slice(0, 200)}
              //       </p>
              //     </div>
              //   </div>
              //   <div className="right">
              //     <span>{Math.floor(Math.random() * 20)} min read</span>
              //   </div>
              // </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default Blog;
