import React, {useEffect} from 'react';
import '../../assets/css/Startup.css';
import Aos from 'aos';
import "aos/dist/aos.css"

export default function Left(props) {
    useEffect( ()=>{
        Aos.init({duration: 2000});
    }, [])
    return (
        <div>
            <div className="card mb-3 card-container">
                <div className="row g-0">
                    <div className="col-md-3"></div>
                        <div className="col-md-3 img_space_left" data-aos="fade-right">
                            <img src = {props.img} className = "startup_right" alt = "ecell"/>
                        </div> 
                        <div className="col-md-6"  data-aos="fade-left">
                            <div className="card-body">
                                <h3 className="card-title">{props.title}</h3>
                                <p className="card-text">{props.founder}, {props.branch}</p>
                                <p className="card-text" style={{color:'#C4C4C4',marginTop:'30px'}}> {props.desc} </p>
                                <div style={{textAlign:"left"}}>
                                    <a className="btn btn-social" href={props.twitter}><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-social" href={props.facebook}><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-social" href={props.linkedin}><i className="fab fa-linkedin"></i></a>
                                </div>
                            </div>
                        </div>
                 </div>
            </div>
        </div>
    )
}
