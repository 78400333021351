import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import Updates from "./components/Updates/updates";
import NewNavbar from "./components/NewNavbar/NewNavbar";
import FooterDurgesh from "./components/Footer/FooterDurgesh";
import LandingPage from "./components/LandingPage/LandingPage";
import Blog from "./components/Blog";
import Startup from "./components/Startup/Startup";
import Events from "./components/Events/Events";
import AboutUs from "./components/AboutUs/Aboutus";

// const LandingPage = React.lazy(() =>
//   import("./components/LandingPage/LandingPage")
// );
// const Blog = React.lazy(() => import("./components/Blog"));
// const Startup = React.lazy(() => import("./components/Startup/Startup"));
// const Events = React.lazy(() => import("./components/Events/Events"));
// const AboutUs = React.lazy(() => import("./components/AboutUs/Aboutus"));

function App() {
  return (
    <div className="App">
      {/* <Updates /> */}
      <NewNavbar />
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/startups" element={<Startup />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/events" element={<Events />} />
        <Route path="/aboutus" element={<AboutUs />} />
      </Routes>
      {/* </Suspense> */}
      <FooterDurgesh />
    </div>
  );
}

export default App;
