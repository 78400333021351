import React, { useState } from "react";

import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import ecellLogo from "./Ecell logo.png";
import kiitLogo from "./kiit logo.png";

const NAVBAER_ROUTES = [
  {
    name: "Home",
    linkTo: "/",
  },
  {
    name: "Startup",
    linkTo: "/startups",
  },
  {
    name: "Events",
    linkTo: "/events",
  },
  {
    name: "Blog",
    linkTo: "/blogs",
  },
  {
    name: "About Us",
    linkTo: "/aboutus",
  },
];

const NewNavbar = () => {
  const [menuActive, setMenuActive] = useState(false);

  return (
    <div className="sticky top-0 z-50 w-full">
      <div className="flex items-center justify-between px-8 py-1 bg-[#000000] z-40 relative">
        <div className="flex items-center gap-4">
          <img src={kiitLogo} alt="ECell Logo" className="w-16 h-14" />
          <img src={ecellLogo} alt="ECell Logo" className="w-18 h-18" />
        </div>

        <ul className="hidden gap-10 laptop:flex">
          {NAVBAER_ROUTES.map((route, i) => {
            return (
              <li key={i} className="group">
                <Link className="font-semibold text-white" to={route.linkTo}>
                  {route.name}
                </Link>
                <div className="group-hover:w-full w-0 h-[2px] bg-blue-500 transition-all duration-300"></div>
              </li>
            );
          })}
        </ul>

        <div className="hidden laptop:inline">
          <a
            href="https://n02n019x6xk.typeform.com/to/FIeN3e3M"
            target="_blank"
            rel="noreferrer"
          >
            <button className=" bg-gradient-to-r from-[#25A8E0] to-[#4936D8] text-white py-1 px-4 rounded-lg">
              Register a Startup
            </button>
          </a>
        </div>

        <div className="inline laptop:hidden">
          <GiHamburgerMenu
            className="text-2xl text-white"
            onClick={() => setMenuActive(!menuActive)}
          />
        </div>
      </div>
      <div
        className={`${
          menuActive ? "flex" : "hidden"
        } relative z-50 w-screen h-screen inline laptop:hidden transition-all duration-300`}
      >
        <ul className="absolute top-0 flex flex-col items-center justify-around w-screen h-screen gap-10 pt-12 text-white bg-black pb-36">
          {NAVBAER_ROUTES.map((route, i) => {
            return (
              <li key={i} className="w-full text-2xl font-semibold ">
                <Link
                  onClick={() => setMenuActive(false)}
                  className="text-white"
                  to={route.linkTo}
                >
                  {route.name}
                </Link>
              </li>
            );
          })}

          <a
            href="https://n02n019x6xk.typeform.com/to/FIeN3e3M"
            target="_blank"
            rel="noreferrer"
          >
            <button className=" bg-gradient-to-r from-[#25A8E0] to-[#4936D8] text-white py-1 px-4 rounded-lg">
              Register a Startup
            </button>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default NewNavbar;
