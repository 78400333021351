import React, { useEffect } from "react";
import Chariman from "./Chariman";
import Companies from "./Companies";
import Departments from "./Departments";
import Events from "./Events";
import HeroSection from "./HeroSection";
import PastSpeakers from "./PastSpeakers";
import Mobile from "./Mobile";
import FAQ from "./FAQ";

import "swiper/css";
import Testimonials from "./Testimonials";

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="mt-16 h-[100%] bg-black text-white">
      <HeroSection />
      <Companies />
      <PastSpeakers />
      <Events />
      <Departments />
      <Chariman />
      <Testimonials />
      <Mobile />
      <FAQ />
    </div>
  );
};

export default LandingPage;
