import React, { useState } from "react";
import "./about.css";
const Notify = () => {
  const [show, setShow] = useState(true);
  const [show2, setshow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClick = (success, e) => {
    e.preventDefault();
    setShow(false);
    if (success) {
      setshow2(true);
    }
  };

  const handleClick2 = (success, e) => {
    e.preventDefault();
    setshow2(false);
    if (success) {
      setShow3(true);
    }
  };

  const handleClick3 = (success, e) => {
    e.preventDefault();
    setShow3(false);
    if (success) {
      setshow2(true);
    }
  };

  return (
    <div className="flex items-center justify-center w-full mb-80">
      <div className="flex flex-col  max-w-[1174px] w-full items-center justify-center px-2">
        {show && (
          <div className="w-full bg-gradient-to-r from-[#25A8E0] to-[#4936D8] py-8 rounded-tl-3xl rounded-br-3xl tablet:px-16 px-2">
            <div className="flex flex-row items-center justify-between px-2 tablet:px-16 mobileL:flex-col">
              <div className="text-3xl font-bold text-left text-white text">
                Want to JoinThis Amazing Team
              </div>
              <button
                onClick={(e) => handleClick(1, e)}
                className="px-4 py-2 text-xl font-semibold text-white border-2"
              >
                Join Us
              </button>
            </div>
          </div>
        )}
        {show2 && (
          <>
            <div className="w-full bg-gradient-to-r from-[#25A8E0] to-[#4936D8] py-8 rounded-tl-3xl rounded-br-3xl tablet:px-16 px-2">
              <div className="flex flex-col items-center justify-center gap-8 px-2 tablet:px-16">
                <div className="flex flex-col gap-8">
                  <p className="text-3xl font-bold text-left text-white text">
                    Want to Join This Amazing Team
                  </p>
                  <input
                    className="px-4 py-2 rounded-lg"
                    id="name"
                    name="name"
                    required
                    minlength="4"
                    size="10"
                    placeholder="@emailid"
                    type="text"
                  />
                </div>
                <button
                  onClick={(e) => handleClick2(1, e)}
                  className="px-4 py-2 text-xl font-semibold text-white border-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
        {show3 && (
          <div className="w-full bg-gradient-to-r from-[#25A8E0] to-[#4936D8] py-8 rounded-tl-3xl rounded-br-3xl tablet:px-16 px-2">
            <div className="flex flex-row items-center justify-between px-2 tablet:px-16 mobileL:flex-col">
              <div className="text-3xl font-bold text-left text-white text">
                You ll Be Notified
              </div>
              <button
                onClick={(e) => handleClick3(1, e)}
                className="px-4 py-2 text-xl font-semibold text-white border-2"
              >
                Join Us
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notify;
