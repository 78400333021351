import React, { useEffect, useState } from "react";
import "../../assets/css/Startup.css";
import Right from "./Right";
import Left from "./Left";
import axios from "axios";
export default function Start() {
  const [startups, setStartups] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get("https://ecell-backend-ophn.onrender.com/getdetails/getall?auth=website")
      .then((res) => {
        setStartups(res.data.startups);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div id="startup">
      <br />
      <br />
      <br />
      <h3 className="title">STARTUPS</h3>
      <br />
      <br />
      <br />

      {/* <div className="search">
                    <TextField className="searchText" fullWidth label="Search..." id="margin-none"
                        InputLabelProps={{ style: { fontSize: 22, color: "whitesmoke", marginLeft: 11 } }}
                        inputProps={{ style: { fontSize: 22, color: "whitesmoke", marginLeft: 11 } }}
                    />
                </div> */}

      <br />
      <br />
      <br />

      {startups.map((startup, i) => {
        return !(i % 2) ? (
          <Left
            title={startup.name}
            img={startup.logo}
            desc=""
            twitter={startup.twitter}
            facebook={startup.facebook}
            linkedin={startup.linkedin}
            founder={startup.founder}
            branch={startup.branch}
          />
        ) : (
          <Right
            title={startup.name}
            img={startup.logo}
            desc=""
            twitter={startup.twitter}
            facebook={startup.facebook}
            linkedin={startup.linkedin}
            founder={startup.founder}
            branch={startup.branch}
          />
        );
      })}

      <br />
      <br />
      <br />
    </div>
  );
}
