import React, { useState, useEffect } from "react";
import Faq from "react-faq-component";
import axios from "axios";

import "./Faq.css";

const styles = {
  bgColor: "#1e1e1e",
  titleTextColor: "white",
  rowTitleColor: "white",
  rowContentColor: " #AAAAAA",
  arrowColor: "white",
  titleTextSize: "48px",
  rowTitleTextSize: "19px",
  rowContentTextSize: "24px",
};

const config = {
  animate: true,
  arrowIcon: "+",
  tabFocus: true,
};

export default function FAQ() {
  const [faqs, setFaqs] = useState([]);
  const rows = [];

  const data = {
    title: "FAQ",
    rows: faqs,
  };

  useEffect(() => {
    axios
      .get(
        "https://ecell-backend-ophn.onrender.com/getdetails/getall?auth=website",
        {
          headers: {
            auth: "website",
          },
        }
      )
      .then((res) => {
        res.data.faq.map((item) => {
          setFaqs((sss) => [
            ...sss,
            {
              title: item.question,
              content: item.answer,
            },
          ]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="flex justify-center w-full">
      <div className="px-8 mx-4 my-40 faq-style-wrapper max-w-[1200px] w-full tablet:mx-40 faq-space">
        <Faq
          data={data}
          styles={styles}
          config={config}
          // getRowOptions={setRowsOption}
        />
      </div>
    </div>
  );
}
