import axios from "axios";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const PastSpeakers = () => {
  const [speakers, setSpeakers] = useState([]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    axios
      .get("https://ecell-backend-ophn.onrender.com/getdetails/getall?auth=website")
      .then((res) => {
        setSpeakers(res.data.speakers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="flex flex-col h-full items-center w-full gap-24 pt-20 bg-gradient-to-r from-[#25A8E0] to-[#4936D8] pb-10 rounded-tl-[50px]">
      <p className="text-4xl font-bold text-white tablet:text-6xl">
        Past Speakers
      </p>

      {/* <div className="flex flex-wrap justify-center gap-6"> */}
      {speakers && (
        <div className="initiatives max-w-[1200px] w-full">
          <Carousel responsive={responsive}>
            {speakers.map((speaker, idx) => (
              <div key={idx} className="flex flex-col p-4 w-60 h-60">
                <img
                  className="rounded-tl-[20%] object-cover w-52 h-52"
                  src={speaker.image}
                  alt={speaker.name}
                />
                <div className="flex flex-col items-center gap-0 ">
                  <p className="text-lg text-white">{speaker.name}</p>
                  <p className="text-sm text-gray-300">{speaker.company}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default PastSpeakers;
