import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// import star from "./assets/Star.svg";
import "./assets/testimonialSwiperStyles.css";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    axios
      .get("https://ecell-backend-ophn.onrender.com/getdetails/getall?auth=website")
      .then((res) => {
        setTestimonials(res.data.testimonials);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="flex justify-center w-full">
      <div className="z-20 flex flex-col items-center w-full max-w-6xl gap-8 py-10 mb-24 mt-44 ">
        <p className="mb-24 text-4xl font-bold text-white sm:text-6xl">
          Testimonials
        </p>

        {testimonials && (
          <div className="initiatives">
            <Carousel responsive={responsive}>
              {testimonials.map((testimony, idx) => (
                <div
                  key={idx}
                  className="flex flex-col items-center w-full py-4"
                >
                  <img
                    className="rounded-tl-[20%] rounded-br-[20%] object-cover w-52 h-52"
                    src={testimony.image}
                    alt={testimony.name}
                  />
                  <div className="flex flex-col items-center gap-0 py-4 ">
                    <p className="text-xl text-white">{testimony.name}</p>
                    <p className="text-sm text-gray-300">{testimony.role}</p>
                    <p className="px-0 mt-4 text-sm text-gray-300 tablet:px-36 text-wrap">
                      {testimony.description}
                    </p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        )}
      </div>
    </div>
  );
};

export default Testimonials;
