import React from "react";
import awarenesCampLogo from "./assets/awarenesCampLogo.png";
import CommunityLearningLogo from "./assets/CommunityLearningLogo.png";
import InternshipCampLogo from "./assets/InternshipCampLogo.png";
const Events = () => {
  return (
    <div className="relative flex flex-col items-center w-full gap-8 pt-24 text-black bg-white pb-44 rounded-bl-[50px]">
      <div className="flex flex-col items-center max-w-lg mb-2 ">
        <p className="text-5xl font-bold text-black tablet:text-6xl">
          Initiatives
        </p>
      </div>

      <div className="flex flex-wrap justify-center gap-4 px-1 mt-36">
        <div className="flex items-start justify-start max-w-sm gap-2">
          <div>
            <img src={awarenesCampLogo} alt="ecell" className="w-52" />
            {/* <GiPlantSeed className="p-0 m-0 text-4xl" /> */}
          </div>
          <div className="flex flex-col gap-2 pr-5 ">
            <p className="text-2xl font-bold text-left">
              Entrepreneurship Awareness Camp
            </p>
            <p className="text-xs text-justify">
              3 Day workshop aimed to develop entrepreneurial aptitude among
              students by holding events with recognized start-up giants.
              E-Summit falls under this initiative.
            </p>
          </div>
        </div>
        <div className="flex items-start justify-start max-w-sm gap-2">
          <div>
            <img src={InternshipCampLogo} alt="ecell" className="w-52" />
            {/* <GiPlantSeed className="p-0 m-0 text-4xl" /> */}
          </div>
          <div className="flex flex-col gap-2 pr-5 ">
            <p className="text-2xl font-bold text-left">Internship Camp</p>
            <p className="text-xs text-justify">
              3 Day workshop aimed to develop entrepreneurial aptitude among
              students by holding events with recognized start-up giants.
              E-Summit falls under this initiative.
            </p>
          </div>
        </div>
        <div className="flex items-start justify-start max-w-sm gap-2">
          <div>
            <img src={CommunityLearningLogo} alt="ecell" className="w-52" />
            {/* <GiPlantSeed className="p-0 m-0 text-4xl" /> */}
          </div>
          <div className="flex flex-col gap-2 pr-4 ">
            <p className="text-2xl font-bold text-left">Community Learning</p>
            <p className="text-xs text-justify">
              3 Day workshop aimed to develop entrepreneurial aptitude among
              students by holding events with recognized start-up giants.
              E-Summit falls under this initiative.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
