import React from "react";

// import departmentsPic from "./assets/departmentsPic.png";
import ChairmanImageAdrita from "./assets/ChairmanImageAdrita.jpeg";
import vector1 from "./assets/Vector.svg";

const Chariman = () => {
  return (
    <div className="flex flex-col items-center w-full gap-24 pt-24 pb-14">
      <div className="flex flex-col items-center gap-20 mt-32 max-w-[1200px] w-full">
        <p className="text-4xl font-bold text-white sm:text-6xl">
          Message From Chairperson
        </p>
        <div className="flex flex-col items-center justify-center gap-20 tablet:flex-row sm:flex-row">
          <div className="flex flex-col items-start gap-10 max-w-[80%]  sm:max-w-[50%]">
            <p className="max-w-2xl text-left text-white ">
              The KIIT Entrepreneurship Cell is driven towards fostering
              entrepreneurship culture amongst students and assisting budding
              entrepreneurs with the necessary tools required to overcome the
              challenges faced in starting new ventures. We are a community for
              anyone interested in corporate life, not just entrepreneurs. KIIT
              E-CELL is a platform that helps you recognize your talents and
              hone them, to make you a future leader and a valuable asset to
              your organization. Working for a greater cause is very special and
              humbling, especially when doing it with such a vibrant team. We
              look forward to supporting more and more entrepreneurial
              activities and inspiring people to live life entrepreneur size.
            </p>
            <p className="text-white">-Adrita Chatterjee</p>
          </div>

          <div className="relative ">
            {/* <img
              className="w-[300px] h-[300px] absolute"
              src={vector1}
              alt="trial"
            /> */}
            <img
              className="w-[300px] h-[300px] z-40"
              src={ChairmanImageAdrita}
              alt="Departments Pic"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chariman;
